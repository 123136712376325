import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Addons = () => (
  <Layout>
    <SEO title="Addons" />
    <div>
      <p>These are the addons I use in arena. You definitely don't need all of these and infact I was able to achieve rank 1 using only OmniBar and BigDebuffs. I use more now just to make things easier for me.</p>
      <ul>
        <li><a href="https://www.curseforge.com/wow/addons/att" target="_blank">Ability Team Tracker</a></li>
        <li><a href="https://www.curseforge.com/wow/addons/bigdebuffs" target="_blank">BigDebuffs</a></li>
        <li><a href="https://www.curseforge.com/wow/addons/details" target="_blank">Details</a></li>
        <li><a href="https://www.curseforge.com/wow/addons/diminish" target="_blank">Diminish</a></li>
        <li><a href="https://www.curseforge.com/wow/addons/gladiatorlossa2" target="_blank">GladiatorlosSA</a></li>
        <li><a href="https://www.curseforge.com/wow/addons/omnibar" target="_blank">OmniBar</a></li>
        <li><a href="https://www.curseforge.com/wow/addons/weakauras-2" target="_blank">WeakAuras</a></li>
      </ul>
      <p>I also have an addon that runs a script everytime I log in to change the names of enemy players on their nameplate into numbers coresponding to their arena frame number. Maybe coming soon but I have not hosted it anywhere yet.</p>
      <p>Some additional popular addons that you might consider using are:</p>
      <ul>
        <li><a href="https://www.curseforge.com/wow/addons/gladius" target="_blank">Gladius</a></li>
      </ul>
    </div>

  </Layout>
)

export default Addons
